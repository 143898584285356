<template lang="pug">
div.right_col
    div.info_wrapper
      div.title
        span Create a video for {{name }}'s birthday
      div.calendar_birthdate
        div.month {{ birthdateMonth }}
        div.day {{birthdateDay}}
      div.howitworks
        div.bold How it works
        ul
          li Record your video for {{firstName}}.
          li You can also customize the prompt.
          li After you record your video, invite friends to record theirs.
          li We’ll send your friends an email from you with a link to add their video
          li Time to relax! We’ve got it from here. We’ll remind people and email you when there’s a new video.
          li
            span On
            span.bold &nbsp; {{ birthdateMonth }} {{ birthdateDay }}, at 5pm EST
            span &nbsp; , the magic happens! Wishwell will generate your final video with music and email it to everyone.

</template>

<script>

import { formatDate } from '../lib/utils'

export default {
  name: 'HowContactBirthdayVideoWorks',
  props: {
    contact: Object
  },
  components: {
  },
  computed: {
    firstName () {
      return this.contact?.firstName
    },
    name () {
      return this.contact?.firstName + " " + this.contact?.lastName
    },
    birthdateMatch () {
      const birthdate = formatDate(this.contact.birthdate)
      return birthdate.match(/^(\w+)\s+(\w+),\s+(\w+)$/)
    },
    birthdateMonth () {
      if (!this.contact) return ""
      return  this.birthdateMatch && this.birthdateMatch[1] ? this.birthdateMatch[1] : ""
    },
    birthdateDay () {
      if (!this.contact) return ""
      return  this.birthdateMatch && this.birthdateMatch[2] ? this.birthdateMatch[2] : ""
    },
    birthdate () {
      if (!this.contact) return ""
      return this.birthdateMonth + " " + this.birthdateDay
    },
  },
  data () {
    return {

    }
  },
  apollo: {
  },
  methods: {

  },
  mounted: function () {

  }
}
</script>

<style scoped>
.right_col {
  position: fixed;
  z-index: 100;
  background-color: #4800CD;
  top: 0px;
  left: 70%;
  height: 100%;
  width: 30%;
  overflow-y: auto;
  overflow-x: hidden;
}

.small .right_col {
  position: relative;
  width: 100%;
  height: initial;
  left: 0px;
}

.info_wrapper {
  padding: 30px;
}

.info_wrapper .title {
  margin: 18px 0 18px 0;
  font-family: gibsonsemibold;
  font-size: 32px;
  color: #ffffff;
}

.info_wrapper .birthdate {
  font-size: 18px;
  color: #ffffff;
  margin: 18px 0 8px 0;
}
.calendar_label {
  width: 60px;
  text-align: center;
  font-family: gibsonsemibold;
  font-size: 14px;
  color: #290174;
  margin-bottom: 4px;
  /* padding: 0px 16px; */
  overflow: visible;
  white-space: nowrap;
}
.calendar_birthdate {
  color: #ffffff;
  width: 60px;
  background: #3D00AE;
  border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  font-family: gibsonsemibold;
  font-size: 16px;
  text-align: center;
  padding: 18px 16px;
  margin: 0 0px 14px 0;
}

.small .info_wrapper {
  margin: 40px 20px;
}

.day {
  margin-top: 4px;
  font-size: 24px;
}

.howitworks {
  margin-top: 24px;
  font-size: 18px;
  color: #d6ccffd8;
  font-family: gibsonregular;
  word-wrap: break-word;
}

.howitworks .bold {
  font-family: gibsonsemibold;
}

ul {
  list-style: circle outside;
  padding: 20px;
}

ul li {
  margin-bottom: 22px;
}

ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  padding-top: 20px;
}

ol li {
  counter-increment: my-awesome-counter;
  margin-bottom: 12px;
}

ol li::before {
  content: counter(my-awesome-counter) ". ";
}

</style>
