<template lang="pug">
div
  div.left_col
    div.wrapper
      div.padding
        Header
        div.title(v-if="contactWithSlug") Record a video for {{name}}
        div.input_container(v-if="project")
          input(
            v-model="prompt"
            type="text"
            placeholder="Type in a prompt for what you want people to record"
            v-on:click="preventPropagation"
            v-on:keyup="keyEnterToSubmit"
            )
        AddVideo(
          v-if="!isSafari && !isIOS"
          :showPrompt="false"
          :project="project"
          v-on:savedVideo="updateAsk"
          v-on:done="next"
          ref="add_video"
        )
        AddVideoWithFileUpload(
          v-if="isIOS"
          :project="project"
          v-on:savedVideo="updateAsk"
          v-on:done="next"
          :showPrompt="false"
        )
  HowContactBirthdayVideoWorks(
    v-if="contactWithSlug"
    :contact="contactWithSlug"
  )
</template>

<script>
/* global gtag */
import config from '../appConfig'
import AddVideo from '../components/AddVideo'
import AddVideoWithFileUpload from '../components/AddVideoWithFileUpload'
import { GET_CONTACT_WITH_SLUG_QUERY } from '../graphql/queries'
import { CREATE_OR_GET_UPCOMING_BIRTHDAY_PROJECT_MUTATION, UPDATE_ASK_MUTATION } from '../graphql/mutations'
import Header from '../components/Header'
import HowContactBirthdayVideoWorks from '../components/HowContactBirthdayVideoWorks'

export default {
  name: 'CreateBirthdayVideoContainer',
  props: {
    slug: {
      type: String,
      default: null
    },
  },
  components: {
    AddVideo,
    AddVideoWithFileUpload,
    Header,
    HowContactBirthdayVideoWorks,
  },
  computed: {
    name () {
      return this.contactWithSlug?.firstName + " " + this.contactWithSlug?.lastName
    },
    firstName () {
      return this.contactWithSlug?.firstName
    },
    isSignedIn () {
      return this.$store.getters.signedIn
    },
  },
  watch: {
    project: function () {
      if (!this.project) return
      this.$store.commit('setProject', this.project)
    },
  },
  data () {
    return {
      prompt: null,
      project:null,
      isIOS: false,
      isSafari: false,
      contactWithSlug: null,
    }
  },
  apollo: {
    contactWithSlug () {
      return {
        // gql query
        query: GET_CONTACT_WITH_SLUG_QUERY,
        variables () {
          return {
            slug: this.slug,
          }
        },
        update: data => {
          return data.contactWithSlug
        },
        error: (error) => {
          console.log(error.message)
        },
        fetchPolicy: 'network-only',
      }
    },
  },
  methods: {
    next: function() {
      this.$router.push('/invite')
    },
    preventPropagation: function(event) {
      if (event) event.stopPropagation()
    },
    keyEnterToSubmit: function (e) {
      if (e.keyCode === 13) {
        this.updateAsk()
      }
    },
    updateAsk: function() {
      this.$apollo.mutate({
        mutation: UPDATE_ASK_MUTATION,
        variables: {
          id: this.project.asks[0].id,
          text: this.prompt,
        },
      }).then((data) => {
        this.loading = false
        this.errorsToast(data.errors)
      }).catch(() => {
        this.loading = false
      })
    },
    createOrGetBirthdayProject: function() {
      this.loading = true
      this.$apollo.mutate({
        mutation: CREATE_OR_GET_UPCOMING_BIRTHDAY_PROJECT_MUTATION,
        variables: {
          contactSlug: this.slug
        },
      }).then((data) => {
        this.project = data.data.createOrGetUpcomingBirthdayProject.project
        this.prompt = this.project.asks[0].text
        this.loading = false
        this.errorsToast(data.errors)
      }).catch((error) => {
        console.error(error)
        this.loading = false
        this.errorToast(error)
      })
    },
    cleanUpVideo: function () {
      if (this.$refs.add_video) this.$refs.add_video.allStop()
    },
  },
  beforeMount: function () {
    this.isSafari = config.isSafari
    this.isIOS = config.isIOS
  },
  beforeRouteLeave(to, from, next) {
    this.cleanUpVideo()
    next()
  },
  beforeUnmount: function() {
    this.cleanUpVideo()
  },
  mounted: function() {
    // window.vue = this
    if (!this.isSignedIn) {
      this.$toast.error("Please sign in first. Click here to close this and we'll take you to the sign in page.",{
        timeout: null,
        onClose: () => this.$router.push({
          name:"LoginWithNext",
          params: {
            next: "/create/birthday/"+this.slug
          }
        })
      })
    } else if (this.isSafari && !this.isIOS) {
      this.$toast.warning(
        "We currently do not support Mac Safari, Please use chrome:  https://www.google.com/chrome",
        {
          timeout: false,
          onClose: () => window.location.href = "https://www.google.com/chrome"
      })
    } else if (this.slug) {
      this.createOrGetBirthdayProject()
    }

    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path':  '/create_birthday_video'})
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.left_col {
  position: fixed;
  z-index: 100;
  background-color: #ffffff;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 70%;
}

.small .left_col {
  position: relative;
  width: 100%;
  height: initial;
}

.wrapper {
  text-align: left;
  height: 100%;
  width: 100%;
}

.padding {
  padding: 3%;
}

.title {
  margin-top: 20%;
  position: relative;
  z-index: 5000;
  text-align: center;
  font-family: gibsonsemibold;
  font-size: 48px;
  color: #ffffff !important;
}

.input_container {
  position: relative;
  z-index: 5000;
  margin-top: 32px;
  background-color: rgba(0, 0, 0, 0.27);
}

.input_container input {
  background-color: rgba(255,255,255,0);
  font-family: gibsonregular;
  font-size: 30px;
  color: #ffffff !important;
  text-align: center;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(233, 225, 225, 0.7);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(255,255,255,0.7);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgba(255,255,255,0.7);
}

</style>
